// 排行类型：1最佳奉献、2最受欢迎、3最牛新人
export const AdviceRankingType = [
  {
    dictTypeName: "排行类型",
    dictType: "advice_ranking_type",
    dictCode: 1,
    dictName: "最佳奉献",
  },
  {
    dictTypeName: "排行类型",
    dictType: "advice_ranking_type",
    dictCode: 2,
    dictName: "最受欢迎",
  },
  {
    dictTypeName: "排行类型",
    dictType: "advice_ranking_type",
    dictCode: 3,
    dictName: "最牛新人",
  },
];
