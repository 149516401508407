import { defineStore } from "pinia";
import { pointsShowFormatter, request } from "@/utils";
import {
  FETCH_CODE,
  FETCH_CONF,
  PAY_TYPE,
  PAY_WAY,
  PRICE_RATE_FRONT,
} from "@/config";
import BigNumber from "bignumber.js";
import { checkPayPwd } from "@/views/mine/api";
import { useTabStore } from "@/stores/tabbar";

export const useUserStore = defineStore("userStore", {
  state: () => {
    return {
      info: {
        id: null,
        delFlag: false,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null,
        userId: null,
        name: null,
        gender: false,
        mobilePhoneCode: null,
        mobilePhoneNumber: null,
        mobilePhoneNumberVerified: false,
        telephoneNumber: null,
        emailAddress: null,
        password: null,
        passwordSalt: null,
        isDisabled: false,
        type: 3,
        headImg: null,
        currentCompany: {
          companyId: null,
          parentCompanyId: null,
          name: null,
          nameShort: null,
          nameOther: null,
          nameOtherShort: null,
          taxCode: null,
          country: null,
          province: null,
          city: null,
          district: null,
          address: null,
          typeId: 0,
          industryId: null,
          scaleId: 0,
          sourceId: 0,
          categoryId: 0,
          intro: null,
          businessUserId: null,
          serviceUserId: null,
        },
        companies: null,
        roleIds: [],
      },
      balance: 0,
      pointsPwdSetted: false,
      // 平台配置信息
      config: {
        name: null,
        logo: null,
        moneyName: null,
        tool: false,
      },
      // 手续费
      serviceCharge: 0,
      // 公司福币余额信息
      companyBalance: 0,
    };
  },
  actions: {
    // 获取用户信息
    async getInfo() {
      const tabbarStore = useTabStore();
      const res = await request({
        url: "/api/user/info",
        method: FETCH_CONF.METHOD.GET,
      });
      if (res.code !== FETCH_CODE.SUCCESS) {
        return;
      }
      this.info = res.data;
      // 平台配置信息设置
      this.config = {
        ...this.config,
        ...res.data.platformConf,
      };
      // 获取手续费
      let serviceCharge = 0.03;
      const dynamicServiceCharge = res.data?.currentCompany?.serviceCharge;
      if (typeof dynamicServiceCharge === "number") {
        serviceCharge = dynamicServiceCharge;
      }
      this.serviceCharge = BigNumber(serviceCharge).times(100).toNumber();
      // 更新本地字典
      PAY_TYPE[0].dictName = `${this.config.moneyName}支付`;
      PAY_WAY.COIN.NAME = `${this.config.moneyName}支付`;
      // 更新tab
      tabbarStore.addSquareTab({
        active: this.config.tool,
      });
      // 更新HTML的title
      document.title = this.config.name;
      // 获取余额
      this.getPoints();
      this.getCompanyBalance();
    },
    // 获取福币余额
    async getPoints() {
      const res = await request({
        url: "/api/user/points",
        method: FETCH_CONF.METHOD.GET,
      });
      if (res.code !== FETCH_CODE.SUCCESS) {
        this.balance = 0;
        return;
      }
      let balance = BigNumber(res.data);
      balance = balance.times(PRICE_RATE_FRONT);
      this.balance = balance.toNumber();
    },
    // 福币支付密码是否已经设置
    async getPointsPwdSetted() {
      const res = await checkPayPwd();
      if (res.code !== FETCH_CODE.SUCCESS) {
        this.pointsPwdSetted = false;
        return;
      }
      this.pointsPwdSetted = !!res.data;
    },
    // 获取公司余额信息
    async getCompanyBalance() {
      const res = await request({
        url: "/api/company/points/get",
        method: FETCH_CONF.METHOD.GET,
        params: {
          companyId: this.info.currentCompany.companyId,
        },
      });
      if (res.code !== FETCH_CODE.SUCCESS) {
        return;
      }
      const curr = res.data?.find((it) => {
        return it.currency === "benefits_coin";
      });
      if (!curr) return;
      this.companyBalance = pointsShowFormatter(curr.points || 0, true);
    },
  },
});
