import { request } from "@/utils";
import { FETCH_CONF } from "@/config";

/**
 * 查询商品属性 - 分页
 * @param {Object} params
 * @param {string=} params.categoryCode 类目code
 * @param {string=} params.categoryName 类目名称(模糊查询)
 * @param {boolean=} params.state 状态（true启用，false禁用）
 * @param {number} params.pageNum 当前页页码
 * @param {number} params.pageSize 每页的数量
 * @returns {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export async function productCatePage(params) {
  return request({
    url: "/api/product/categroy/page",
    method: FETCH_CONF.METHOD.POST,
    params,
  });
}

export async function productCateSave(params) {
  return request({
    url: "/api/product/categroy/save",
    method: FETCH_CONF.METHOD.POST,
    params,
  });
}

/**
 * 查询商品列表-分页
 * @param params
 * @param {number} params.pageNum
 * @param {number} params.pageSize
 * @param {string=} params.brandCode
 * @param {string=} params.categoryCode
 * @param {string=} params.productType
 * @param {string=} params.search
 * @param {string=} params.sort 排序(接口返回列名称+_asc/_desc，例如：saleNumber_asc、saleNumber_desc、minSalesPrice_asc、minSalesPrice_desc)
 * @param {boolean=} params.state
 * @param {string=} params.supplierCode
 * @param {boolean=} params.upAndDownShelfState
 * @returns {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export async function productListPage(params) {
  return request({
    url: "/api/product/client/page",
    method: FETCH_CONF.METHOD.POST,
    params,
  });
}

/**
 * 查询商品详情
 * @param params
 * @param {string} params.productNo
 * @returns {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export async function productDetail(params) {
  return request({
    url: "/api/product/client/detail",
    method: FETCH_CONF.METHOD.GET,
    params,
  });
}

/**
 * 供应商列表 不分页
 * @param params
 * @returns {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export async function supplierList(params) {
  return request({
    url: "/api/product/supplier/list",
    method: FETCH_CONF.METHOD.POST,
    params,
  });
}
