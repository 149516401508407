<script setup>
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
});

const router = useRouter();
const userStore = useUserStore();

const viewDetail = () => {
  router.push(`/goods/detail/${props.item.productNo}`);
};
</script>

<template>
  <div class="goods-item" @click="viewDetail">
    <!-- 图片 -->
    <div
      class="goods-item-image"
      :style="{ backgroundImage: `url(${props.item.pictureUrl})` }"
    >
      <div class="goods-item-image-icon" v-if="props.item.supplierName">
        {{ props.item.supplierName }}
      </div>
    </div>
    <!-- 描述 -->
    <div class="goods-item-text">
      {{ props.item.productName }}
    </div>
    <!-- 价格 -->
    <div class="goods-item-bottom">
      <span>
        {{ props.item.salesPrice }}
        <span class="goods-item-bottom-money">
          {{ userStore.config.moneyName }}
        </span>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.goods-item {
  width: 48%;
  background: $color-white;
  box-shadow: 0px 4px 4px 0px $shadow-color;
  border-radius: 6px;
  padding-bottom: $gutter15;
  &-image {
    width: 100%;
    height: 147px;
    position: relative;
    border-radius: 6px;
    @include background(unset, cover);
    &-icon {
      padding: 0 10px;
      height: 20px;
      background: $color-primary;
      border-radius: 6px 6px 6px 0px;
      position: absolute;
      top: 0;
      right: 0;
      color: $color-white;
      font-size: $font-small;
      text-align: center;
    }
  }
  &-text {
    margin-top: 12px;
    margin-bottom: 8px;
    @include single-hidden();
    font-size: $font-small;
    color: $color-text;
    line-height: 1;
    padding: 0 8px;
  }
  &-bottom {
    padding: 0 8px;
    @include flex-center();
    color: $color-primary;
    &-money {
      color: $sub-color;
      font-size: $font-small;
    }
    &-icon {
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      flex-grow: 0;
      @include background("@/assets/images/plus-circle@2x.png");
    }
  }
}
</style>
