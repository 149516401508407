import { defineStore } from "pinia";
import { cloneDeep } from "lodash";

export const TabList = [
  {
    key: "1",
    tabName: "首页",
    icon: "ec-ga-icon-home",
    path: "/",
  },
  {
    key: "2",
    tabName: "商城",
    icon: "ec-ga-icon-shopping",
    path: "/mall",
  },
  {
    key: "3",
    tabName: "广场",
    icon: "ec-ga-icon-square",
    path: "/square",
  },
  {
    key: "4",
    tabName: "我的",
    icon: "ec-ga-icon-mine",
    path: "/mine",
    children: [
      "/mine/setting",
      "/mine/password/login",
      "/mine/password/payment",
    ],
  },
];

export const useTabStore = defineStore({
  id: "tab",
  state: () => ({
    active: TabList[0].key,
    tabList: TabList.filter((it) => !["3"].includes(it.key)),
  }),
  getters: {},
  actions: {
    setActive(key) {
      this.active = key;
    },
    addSquareTab(options) {
      if (!options.active) return;
      const existed = this.tabList.some((it) => ["3"].includes(it.key));
      if (existed) return;
      this.tabList.splice(2, 0, cloneDeep(TabList[2]));
    },
  },
});
