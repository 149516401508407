import { defineStore } from "pinia";
import {
  ADVICE_STATUS,
  AdviceRankingType,
  DateFormatFull,
  FETCH_CODE,
} from "@/config";
import { rankAdvice } from "@/views/square/api";
import moment from "moment";
import { getFakeId } from "@/utils";
import { cloneDeep } from "lodash";

export const SquareTabList = [
  {
    paneKey: ADVICE_STATUS.PASSED.CD,
    title: "已通过",
    key: 1,
    loaded: true, // tab panel support lazy load
  },
  {
    paneKey: ADVICE_STATUS.REVIEW.CD,
    title: "待审核",
    loaded: false,
    key: 2,
  },
  {
    paneKey: ADVICE_STATUS.INVALID.CD,
    title: "未通过",
    loaded: false,
    key: 3,
  },
];

export const useSquareStore = defineStore({
  id: "square",
  state: () => ({
    activeTab: SquareTabList[0].paneKey,
    squareTabList: cloneDeep(SquareTabList).filter((it) => {
      return it.paneKey !== ADVICE_STATUS.REVIEW.CD;
    }),
    rankList: [],
  }),
  actions: {
    setActiveTab(key) {
      this.activeTab = key;
    },
    setLoaded(key) {
      const current = this.squareTabList.find((it) => it.paneKey === key);
      if (!current) return;
      current.loaded = true;
    },
    setTabList(tab) {
      this.squareTabList = tab;
    },
    // 查询最近一年的排名情况
    async getRankList() {
      const res = await rankAdvice({
        startTime: moment()
          .add(-1, "years")
          .startOf("day")
          .format(DateFormatFull),
        endTime: moment().endOf("day").format(DateFormatFull),
      });
      if (res.code !== FETCH_CODE.SUCCESS) {
        return;
      }
      res.data = res.data || [];
      // 按照 AdviceRankingType 排序
      const rankList = [];
      for (const type of AdviceRankingType) {
        const curr = res.data.find((it) => it.rankingType === type.dictCode);
        let color;
        if (type.dictCode === AdviceRankingType[0].dictCode) {
          color = "#FF8C26";
        }
        if (type.dictCode === AdviceRankingType[1].dictCode) {
          color = "#F03738";
        }
        if (type.dictCode === AdviceRankingType[2].dictCode) {
          color = "#2B88FF";
        }
        if (curr) {
          curr.rankingTypeName = type.dictName;
          curr.color = color;
          rankList.push({ ...curr });
        }
        // 未查找到数据
        else {
          rankList.push({
            userId: getFakeId(),
            rankingType: type.dictCode,
            rankingTypeName: type.dictName,
            headImg: null,
            userName: "虚位以待",
            color: color,
          });
        }
      }
      this.rankList = rankList;
    },
  },
});
