import { request, showToast } from "@/utils";
import { FETCH_CODE, FETCH_CONF } from "@/config";

/**
 * 获取字典
 * @param params
 * @param {string} params.dictType 字典编码
 * @returns {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export async function getDic(params) {
  return request({
    url: "/api/sys/dict/get",
    method: FETCH_CONF.METHOD.GET,
    params,
  });
}

/**
 * 获取系统配置
 * @param params
 * @param {string} params.search 查询的系统配置编码
 * @return {Promise<{code?:string,id?:number,name?:string,value?:string,remark?:string,isEncryption?:boolean}>}
 */
export async function getSys(params) {
  const res = await request({
    url: "/api/sys/data/page",
    method: FETCH_CONF.METHOD.POST,
    params: {
      ...params,
      pageNum: 1,
      pageSize: 1,
      state: true,
    },
  });
  if (res.code !== FETCH_CODE.SUCCESS) {
    showToast(res);
    return {};
  }
  return res.data.records[0] || {};
}

/**
 * 创建支付订单 - 测试接口
 * @param params
 * @param {string} params.openId 微信openId，支付方式为微信JSAPI时必传
 * @param {string} params.outTradeNo 应用内订单号
 * @param {string} params.paymentType 支付方式(PAYMENT_TYPE)，1:微信JSAPI、2:微信NATIVE、3:微信H5、4:微信APP、5:支付宝网页、6:支付宝扫码
 * @param {string} params.totalAmount 订单金额，单位：分
 * @param {string} params.tradeDesc 交易描述
 * @returns {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export async function testPayOrder(params) {
  return request({
    url: "/api/pay/createOrder",
    method: FETCH_CONF.METHOD.POST,
    params,
  });
}

/**
 * 微信支付结果查询接口
 * @param params
 * @param {string} params.outTradeNo 应用订单号
 * @return {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export function queryPayResult(params) {
  return request({
    url: "/api/pay/queryOrder",
    method: FETCH_CONF.METHOD.GET,
    params,
  });
}

/**
 * 根据简称获取公司配置
 * @param params
 * @param {string} params.alias
 * @return {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export function getConfByAlias(params) {
  return request({
    url: "/api/company/platform/conf/getByAlias",
    method: FETCH_CONF.METHOD.GET,
    params,
  });
}
