import { request } from "@/utils";
import { FETCH_CONF } from "@/config";

/**
 * 获取推荐位banner
 * @param {Object} params
 * @param {string} params.bannerType
 * @returns {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export function getBanner(params) {
  return request({
    url: "/api/sys/banner/get",
    method: FETCH_CONF.METHOD.GET,
    params,
  });
}

/**
 * 获取推荐商品列表 分页
 * @param params
 * @param {number} params.pageNum
 * @param {number} params.pageSize
 * @returns {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export function recommendProductPage(params) {
  return request({
    url: "/api/product/recommend/page",
    method: FETCH_CONF.METHOD.POST,
    params,
  });
}
