export const ADVICE_STATUS = {
  PASSED: {
    CD: "passed",
    NAME: "已通过",
  },
  REVIEW: {
    CD: "un_approved",
    NAME: "待审核",
  },
  INVALID: {
    CD: "rejected",
    NAME: "未通过",
  },
};
