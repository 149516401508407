import { request } from "@/utils";
import { FETCH_CONF } from "@/config";
// 是否具有审批权限
export async function getAuth(params) {
  return request({
    url: "/api/user/propose/approval/auth",
    method: FETCH_CONF.METHOD.GET,
    params,
  });
}
// 所有用户建议
export async function getUserAdvice(params) {
  return request({
    url: "/api/user/propose/page",
    method: FETCH_CONF.METHOD.POST,
    params,
  });
}

// 审核拒绝
export async function rejectAdvice(params) {
  return request({
    url: "/api/user/propose/approval/reject",
    method: FETCH_CONF.METHOD.POST,
    params,
  });
}

// 审核通过
export async function resolveAdvice(params) {
  return request({
    url: "/api/user/propose/approval/pass",
    method: FETCH_CONF.METHOD.POST,
    params,
  });
}

/**
 * 建议打赏
 * @param params
 * @param {string} params.payPassword 	支付密码
 * @param {number} params.points 打赏福币数量
 * @param {number} params.userProposeId
 * @returns {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export function rewardAdvice(params) {
  return request({
    url: "/api/user/propose/reward",
    method: FETCH_CONF.METHOD.POST,
    params,
  });
}

/**
 * 点赞
 * @param params
 * @param {boolean} params.isZan
 * @param {number} params.userProposeId
 * @return {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export function favoriteAdvice(params) {
  return request({
    url: "/api/user/propose/dianzan",
    method: FETCH_CONF.METHOD.GET,
    params,
  });
}

/**
 * 积分打赏、奖励、赠送（员工之间）
 * @param params
 * @param {string} params.otherId 其他id（福利Id、员工建议id等）
 * @param {string} params.payPassword 支付密码
 * @param {number} params.points 金额
 * @param {string} params.purpose 用途
 * @param {string} params.remark 	备注
 * @param {string[]} params.userIds 用户Id集合
 * @return {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export function rewardPoints(params) {
  return request({
    url: "/api/company/points/reward",
    method: FETCH_CONF.METHOD.POST,
    params,
  });
}

export function getPointsPage(params) {
  return request({
    url: "/api/user/propose/reward/points/page",
    method: FETCH_CONF.METHOD.POST,
    params,
  });
}

/**
 * 查询用户建议排行榜
 * @param params
 * @param {number=} params.count 每种排行前几名(默认1)
 * @param {string} params.endTime 	结束时间
 * @param {string} params.startTime 	开始时间
 * @return {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export function rankAdvice(params) {
  return request({
    url: "/api/user/propose/ranking",
    method: FETCH_CONF.METHOD.POST,
    params,
  });
}
