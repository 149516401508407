import { getUrlParams, parseGetUrl } from "./tools";
import { API_CODE, AUTH, clearAuth, FETCH_CODE, FETCH_CONF } from "@/config";
import { Wechat } from "@/utils/wechat";
import { ROUTE_WHITE_LIST } from "@/router";
import { Toast } from "@nutui/nutui";

const toastId = "FETCH_TOAST_LOADING";

/**
 * 异步请求
 * @param {Object} options
 * @param {string} options.url 请求地址
 * @param {{[k:string]:any}=} options.params 请求参数
 * @param {string=} options.method 请求类型
 * @return {Promise<{code:0|1|2,msg:string,data:any}>}
 */
export function request(options) {
  Toast.loading("加载中", { cover: true, id: toastId });
  const merged = {
    params: {},
    method: FETCH_CONF.METHOD.POST,
    timeout: FETCH_CONF.TIMEOUT,
    ...options,
  };
  const { url, params, method, timeout } = merged;
  const response = { code: FETCH_CODE.SUCCESS, msg: "succeed" };
  const controller = new AbortController();
  const signal = controller.signal;

  const timer = setTimeout(() => {
    controller.abort();
  }, timeout);

  return new Promise((resolve) => {
    if (!url) {
      response.code = FETCH_CODE.ERROR;
      response.msg = "url is required";
      resolve(response);
      console.error("url is required");
      return;
    }

    let requestUrl = url; // .replace(/^\//, "");
    const requestMethod = method.toUpperCase();
    if (requestMethod === FETCH_CONF.METHOD.GET) {
      const getParams = { v: +new Date(), ...params };
      requestUrl = parseGetUrl(requestUrl, {
        ...getParams,
      });
    }
    let requestBody = undefined;
    if (requestMethod === FETCH_CONF.METHOD.POST) {
      requestBody = JSON.stringify(params);
    }

    fetch(`${requestUrl}`, {
      signal,
      headers: {
        "Content-Type": "application/json; utf-8",
        Authorization: AUTH.TOKEN,
      },
      mode: "cors",
      credentials: "include",
      method: requestMethod,
      body: requestBody,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.code === API_CODE.SUCCESS) {
          response.data = res.data;
          resolve(response);
          return;
        }
        response.code = FETCH_CODE.ERROR;
        // 多机构处理
        if (res.code === 1005) {
          response.code = res.code;
        }
        response.msg = res.msg;
        resolve(response);
        // 未绑定处理
        if (res.code === API_CODE.UN_BIND) {
          Wechat.goOAuth2(
            parseGetUrl("/account", {
              c: getUrlParams("c"),
            }),
          );
        }
        // 登录失效 清除本地token并重新登录
        if (res.code === API_CODE.INVALID) {
          clearAuth();
          // 防止重复刷新
          const isWhite = ROUTE_WHITE_LIST.some((white) => {
            return new RegExp(white).test(window.location.pathname);
          });
          if (!isWhite) {
            Wechat.goOAuth2();
          }
        }
      })
      .catch((err) => {
        response.code = FETCH_CODE.ERROR;
        response.msg = err.message;
        resolve(response);
      })
      .finally(() => {
        clearTimeout(timer);
        Toast.hide(toastId);
      });
  });
}
