// 前端请求状态编码
export const FETCH_CODE = {
  // 请求成功
  SUCCESS: 0,
  // 请求成功但是有警告
  WARNING: 1,
  // 请求失败
  ERROR: 2,
  // 弹窗警告
  ALERT: 3,
  // 弹窗询问
  CONFIRM: 4,
  // 多机构
  MULTI_COMPANY: 1005,
};

// 后端约定请求状态编码
export const API_CODE = {
  // 请求成功
  SUCCESS: 200,
  // 未绑定
  UN_BIND: 507,
  // 登录失效
  INVALID: 401,
};

// 前端请求配置
export const FETCH_CONF = {
  // 请求超时
  TIMEOUT: 100000,
  // 请求类型
  METHOD: {
    GET: "GET",
    POST: "POST",
  },
};
