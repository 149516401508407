<script setup>
import { useRoute } from "vue-router";
import ECtabbar from "@/views/home/Tabbar.vue";
import { ref } from "vue";

const route = useRoute();
const mainBodyClassList = ref([]);

const onScroll = (e) => {
  // 首页滚动事件
  if (route.fullPath === "/") {
    const ecHomeClass = "ec-home-header-sticky";
    const homeLogo = document.getElementById("ecHomeLogo");
    if (!homeLogo) return;
    const homeLogoHeight = getComputedStyle(homeLogo).height;
    const ecHomeClassIdx = mainBodyClassList.value.indexOf(ecHomeClass);
    if (e.target.scrollTop > parseFloat(homeLogoHeight)) {
      if (ecHomeClassIdx < 0) {
        mainBodyClassList.value.push(ecHomeClass);
      }
      return;
    }
    if (ecHomeClassIdx > -1) {
      mainBodyClassList.value.splice(ecHomeClassIdx, 1);
    }
  }
};
</script>

<template>
  <div class="ec-main-content">
    <div
      :class="[
        route.meta.title ? 'ec-main-body ec-main-height' : 'ec-main-body',
        route.meta?.tabbar === false ? 'no-tabbar' : '',
        ...mainBodyClassList,
      ]"
      @scroll="onScroll"
      id="ecMainBody"
    >
      <RouterView />
      <ECtabbar v-if="route.meta?.tabbar !== false" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ec-main-back {
  font-size: 20px;
  display: block;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: -10px;
  text-align: left;
  color: $color-text;
}
.ec-main-header {
  height: $logo-h;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 23px;

  .main-logo {
    width: 98px;
    height: 30px;
  }
}
.ec-main-body {
  max-height: calc(100vh - $tabbar-h);
  overflow-y: auto;

  &.no-tabbar {
    max-height: 100vh;
  }

  &.ec-home-header-sticky {
    :deep(.ec-home-header) {
      background: #fbe0d1;
      box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
