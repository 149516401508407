import { parseGetUrl, request } from "@/utils";
import { FETCH_CONF } from "@/config";

/**
 * 修改支付密码
 * @param {Object} params
 * @param {string} params.newPassword 新密码
 * @param {string} params.oldPassword 旧密码
 * @returns {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export async function updatePayPwd(params) {
  return request({
    url: "/api/user/update/payPassword",
    method: FETCH_CONF.METHOD.POST,
    params,
  });
}

/**
 * 修改登录密码
 * @param params
 * @param {string=} params.newPassword
 * @param {string=} params.oldPassword
 * @returns {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export async function updateLoginPwd(params) {
  return request({
    url: "/api/user/password/update",
    method: FETCH_CONF.METHOD.POST,
    params,
  });
}

/**
 * 积分记录
 * @param params
 * @param {number} params.pageNum 当前页页码
 * @param {number} params.pageSize 每页的数量
 * @param {number=} params.source 来源 1:登录,2:签到,51:消费
 * @param {string=} params.userId 用户id
 * @returns {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export async function pointsList(params) {
  return request({
    url: "/api/user/points/list",
    method: FETCH_CONF.METHOD.GET,
    params,
  });
}

/**
 * 检查支付密码是否设置
 * @param {Object=} params
 * @returns {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export async function checkPayPwd(params) {
  return request({
    url: "/api/user/checkPayPwd",
    method: FETCH_CONF.METHOD.GET,
    params,
  });
}

/**
 * 申请退款
 * @param params
 * @param {boolean=} params.delFlag 是否禁用（0 启用 1禁用)
 * @param {number=} params.goodsStatus 商品状态 1 未收到货 2已收货
 * @param {number=} params.id id
 * @param {string=} params.img 图片
 * @param {string=} params.orderId 订单id
 * @param {string=} params.reason 售后原因
 * @param {string=} params.remark 备注
 * @param {number=} params.status 申请状态 1 待审核 2已通过 3已拒绝 4已取消
 * @param {number=} params.type 售后类型 1 仅退款 2 退货退款
 * @returns {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export function refundOrder(params) {
  return request({
    url: "/api/product/order/refund/apply",
    method: FETCH_CONF.METHOD.POST,
    params,
  });
}

/**
 * 取消退款申请
 * @param params
 * @param {string} params.orderId 订单id
 * @returns {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export function unRefundOrder(params) {
  return request({
    url: "/api/product/order/refund/cancel",
    method: FETCH_CONF.METHOD.POST,
    params,
  });
}

/**
 * 查询快递路由(根据订单id)
 * @param params
 * @param {string} params.orderId
 * @returns {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export function queryExpressByOrderId(params) {
  return request({
    url: "/api/sys/express/queryByOrderId",
    method: FETCH_CONF.METHOD.GET,
    params,
  });
}

/**
 * 取消订单
 * @param params
 * @param {string} params.orderId
 * @return {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export function cancelOrder(params) {
  let url = "/api/product/order/cancel";
  url = parseGetUrl(url, params);
  return request({
    url: url,
    method: FETCH_CONF.METHOD.POST,
    params,
  });
}

/**
 * 确认收货
 * @param params
 * @param {string} params.orderId
 * @return {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export function confirmOrder(params) {
  let url = "/api/product/order/confirmReceipt";
  url = parseGetUrl(url, params);
  return request({
    url: url,
    method: FETCH_CONF.METHOD.POST,
    params,
  });
}

/**
 * 获取员工用户点赞奖励汇总
 * @param {Object=} params
 * @return {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export function proposeGather(params) {
  return request({
    url: "/api/user/propose/gather/get",
    method: FETCH_CONF.METHOD.GET,
    params,
  });
}

/**
 * 获取企业币种兑换比例列表
 * @param params
 * @param {string} params.companyId 企业id
 * @param {string} params.fromCurrency 来源币种
 * @param {string} params.toCurrency 转换币种
 * @param {Number} params.pageNum 页数
 * @param {Number} params.pageSize 每页大小
 * @returns {Promise<{code: (0|1|2), msg: string, data: *}>}
 */
export function exchangePointsRate(params) {
  return request({
    url: "/api/company/points/rate/page",
    method: FETCH_CONF.METHOD.POST,
    params,
  });
}
