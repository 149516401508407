import {
  FETCH_CODE,
  PICTURE_SPLITTER,
  PRICE_DECIMALS,
  PRICE_RATE_BACK,
  PRICE_RATE_FRONT,
  REG_MOBILE,
  REG_PASSWORD,
} from "@/config";
import BigNumber from "bignumber.js";
import { getDic } from "@/views/api";

export function getType(val) {
  return Object.prototype.toString.call(val).slice(8, -1).toLowerCase();
}

export function getTargetClassEle(target, className) {
  try {
    let ele = target;
    if (!ele) return null;
    while (!ele.classList?.contains(className)) {
      ele = ele.parentNode;
    }
    return ele ?? null;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export function parseGetUrl(url, data) {
  if (!data) return url;
  // check url has ?
  const parseUrl = () => {
    if (/\?/.test(url)) {
      // is end of ?
      if (/\?$/.test(url)) {
        return url;
      }
      return url + "&";
    }
    return url + "?";
  };

  url = parseUrl();

  if (getType(data) === "String") return url + data;
  let str = "";
  const values = Object.values(data);
  Object.keys(data).forEach((key, idx) => {
    let val = values[idx];
    if (val == null) val = "";
    str += `${key}=${"" + val}&`;
  });
  str = str.replace(/&$/, "");
  return url + str;
}

export function getUrlParams(key) {
  const UrlParams = new URLSearchParams(window.location.search.split("?")[1]);
  return UrlParams.get(key);
}

export function getStyles(ele) {
  if (!ele) return {};
  if (window.getComputedStyle) {
    return window.getComputedStyle(ele, null);
  } else {
    return ele.currentStyle;
  }
}

export function formatNumber(number, precision = 0, isRound = true) {
  if (getType(precision) !== "number") precision = 0;
  let num;
  if (getType(number) !== "number") {
    num = 0;
    return num.toFixed(precision);
  }
  if (isRound) {
    num =
      Math.round(number * Math.pow(10, precision)) / Math.pow(10, precision);
  } else {
    num =
      Math.floor(number * Math.pow(10, precision)) / Math.pow(10, precision);
  }
  return isNaN(num) ? "" : num.toFixed(precision);
}

export function formatMoney(value, precision = PRICE_DECIMALS) {
  if (getType(value) !== "number") {
    return value;
  }
  if (value < 10000) {
    return formatNumber(value, precision, false);
  }
  if (value < 100000000) {
    const val = value / 10000;
    return formatNumber(val, precision, false) + "万";
  }
  if (value < 1000000000000) {
    const val = value / 100000000;
    return formatNumber(val, precision, false) + "亿";
  }
  return "9999亿+";
}

/**
 * 积分、福币、金额展示格式化方法
 * @param {number} number
 * @param {boolean=} isToNumber
 * @return {string|number}
 */
export function pointsShowFormatter(number, isToNumber) {
  if (isToNumber) {
    return BigNumber(number).times(PRICE_RATE_FRONT).toNumber();
  }
  return BigNumber(number).times(PRICE_RATE_FRONT).toFixed(PRICE_DECIMALS);
}

/**
 * 积分、福币、金额存储格式化方法
 * @param number
 * @return {number}
 */
export function pointsSaveFormatter(number) {
  return BigNumber(number).times(PRICE_RATE_BACK).toNumber();
}

/**
 * 积分、福币、金额展示格式化方法 列表序列化 转换后的值为：show[原键名]
 * @param {{[key: string]: any}[]} list 列表
 * @param {string[]|string} keys 要转换的键 支持单个（传字符串）或者批量（传数组）
 * @return {{[key: string]: any}[]}
 */
export function listPointsShowFormatter(list, keys) {
  if (getType(keys) === "string") keys = [keys];
  return list.map((it) => {
    for (const key of keys) {
      if (it[key] !== void 0) {
        it[`${key}Show`] = pointsShowFormatter(it[key]);
      }
    }
    return { ...it };
  });
}

export async function checkFormRules(rules, value) {
  const errors = [];
  if (!rules || getType(rules) !== "array" || !rules.length) {
    return errors;
  }
  for (const rule of rules) {
    // 空校验 - 有非空校验 如果校验不通过 其他就不校验了
    if (
      rule.required &&
      (value === "" || value === null || value === undefined)
    ) {
      errors.push(rule.message || "请输入值");
      return errors;
    }
    // 手机号校验
    if (rule.mobile && !REG_MOBILE.test(value)) {
      errors.push(rule.message || "请输入正确的手机号码");
    }
    // 额定长度校验
    if (getType(rule.length) === "number" && value.length !== rule.length) {
      errors.push(rule.message || `长度必须为${rule.length}`);
    }
    // 最大长度校验
    if (getType(rule.max) === "number" && value.length > rule.max) {
      errors.push(rule.message || `长度不能超过${rule.max}`);
    }
    // 最小长度校验
    if (getType(rule.min) === "number" && value.length < rule.min) {
      errors.push(rule.message || `长度不能少于${rule.min}`);
    }
    // 密码校验 必须包含数字、字母
    if (rule.password && !REG_PASSWORD.test(value)) {
      errors.push(rule.message || "密码必须由数字及字母组成");
    }
    // 自定义校验
    if (getType(rule.validator) === "function") {
      let _valid = true;
      let _validMsg = null;
      try {
        await rule.validator(value);
      } catch (e) {
        _valid = false;
        _validMsg = e;
      }
      if (!_valid) {
        errors.push(_validMsg || "校验失败");
      }
    }
  }
  return errors;
}

export function getFakeId() {
  const str = () => Math.random().toString(16).slice(2, 10);
  return `_local${new Date().getTime()}-${str()}-${str()}`;
}

export function productItemImgFormatter(pictureUrl) {
  return pictureUrl ? pictureUrl.split(PICTURE_SPLITTER)[0] : "";
}

export function productItemFormatter(item) {
  let salesPrice = item.minSalesPrice || item.skuList[0]?.salesPrice;
  salesPrice = BigNumber(salesPrice);
  salesPrice = salesPrice.times(PRICE_RATE_FRONT);
  return {
    ...item,
    salesPrice: salesPrice.toFixed(PRICE_DECIMALS),
    pictureUrl: productItemImgFormatter(item.pictureUrl),
  };
}

// 判断是否是微信环境
export function isWechat() {
  return /MicroMessenger/i.test(window.navigator.userAgent);
}

/**
 * 列表字典反转name
 * @param {object[]} list
 * @param {{dictType:string|Array,key:string,local?:boolean}[]} dicList
 * @param {string|Array} dicList[].dictType 字典编码，当为本地硬编码时，为硬编码数组
 * @param {string} dicList[].key 要翻转的字段名
 * @param {boolean?} dicList[].local 是否本地硬编码
 * @return {Promise<Array>}
 */
export function listDicFormatter(list, dicList) {
  const ls = list.slice();
  const dicApis = [];
  const dic0bj = {};
  dicList.forEach((it) => {
    // 本地字典（未配置到系统中 硬编码）
    if (it.local) {
      dic0bj[it.key] = it.dictType;
      return;
    }
    const fn = async () => {
      const res = await getDic({ dictType: it.dictType });
      if (res.code === FETCH_CODE.SUCCESS) {
        return { key: it.key, data: res.data };
      }
      return false;
    };
    dicApis.push(fn());
  });
  return new Promise((resolve) => {
    Promise.all(dicApis).then((res) => {
      res.forEach((it) => {
        if (!it) return;
        dic0bj[it.key] = it.data;
      });

      ls.forEach((it) => {
        for (const key in dic0bj) {
          if (it[key] !== void 0) {
            const curr = dic0bj[key].find((_it) => _it.dictCode === it[key]);
            if (curr) {
              it[`${key}Name`] = curr.dictName;
            }
          }
        }
      });

      resolve(ls);
    });
  });
}
